import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://dc987c0c31f842d39f57cbef82941e71@sentry.outspoke.io/7",
    sampleRate: 0.7,
    integrations: [
        new BrowserTracing({
          tracePropagationTargets: ["localhost", "outspoke.io", /^\//],
        }),
      ],
});